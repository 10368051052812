body {
  background-color: #ffffff;
  color: #323250;
  background-image: url("/img/clean-textile.png");
}

.header-container,
.body-container,
.footer-container {
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  fill: #333333;
  color: #333333;
}

.footer-container {
  padding-top: 24px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Arapey", serif;
}

h1 {
  font-size: 6em;
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
  letter-spacing: 0.1em;
}

h1 small {
  font-size: 0.3em;
  letter-spacing: 0;
}

h2 {
  font-size: 4em;
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
  letter-spacing: 0.1em;
}

h4 {
  font-size: 1.75em;
  letter-spacing: 0.2em;
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
  font-variant: small-caps;
}

h5 {
  font-size: 1.5em;
  letter-spacing: 0.2em;
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
}

h6 {
  font-size: 1.3em;
  letter-spacing: 0.1em;
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
}

p {
  font-size: 1.2em;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 3.5em;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
    letter-spacing: 0.05em;
  }
  h2 {
    font-size: 2.5em;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
    letter-spacing: 0.1em;
  }
}

.container {
  display: flex;
  padding-top: 20px;
}

.halfDiv {
  width: 50%;
  display: inline-block;
  align-self: flex-end;
}

@media screen and (max-width: 768px) {
  .container {
    display: block;
  }
  .halfDiv {
    width: 100%;
    display: inline-block;
  }
}


hr {
  margin-top: 24px;
  margin-bottom: 24px;
}
.menu-list {
  list-style-type: none;
  padding-inline-start: 0;
  padding-bottom: 1em;
}

.menu-list li {
  display: inline;
  font-size: 1.2em;
  letter-spacing: 0.1em;
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
  font-variant: small-caps;
}

.menu-list li a {
  color: inherit;
  text-decoration: none;
  padding: 0.3em 0.3em;
  margin: 0 0.3em;
}

.menu-list li a:hover {
  /*background: #ddd; */
  background: radial-gradient(
    ellipse at center,
    rgba(221, 221, 221, 1) 0%,
    rgba(221, 221, 221, 0) 90%,
    rgba(221, 221, 221, 0) 100%
  );
  /*border-radius: 10px;*/
}

p a {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
}
p a:hover {
  /*background: #ddd; */
  background: radial-gradient(
    ellipse at center,
    rgba(221, 221, 221, 1) 0%,
    rgba(221, 221, 221, 0) 90%,
    rgba(221, 221, 221, 0) 100%
  );
  /*border-radius: 10px;*/
}

h6 a {
  font-family: 'Old Standard TT', serif;
  color: inherit;
  text-decoration: none;
  font-weight: bold;
}
h6 a:hover {
  /*background: #ddd; */
  background: radial-gradient(
    ellipse at center,
    rgba(221, 221, 221, 1) 0%,
    rgba(221, 221, 221, 0) 90%,
    rgba(221, 221, 221, 0) 100%
  );
  /*border-radius: 10px;*/
}

h4 a {
  font-family: 'Old Standard TT', serif;
  color: inherit;
  text-decoration: none;
  font-weight: bold;
}

h4 a:hover {
  /*background: #ddd; */
  background: radial-gradient(
    ellipse at center,
    rgba(221, 221, 221, 1) 0%,
    rgba(221, 221, 221, 0) 90%,
    rgba(221, 221, 221, 0) 100%
  );
  /*border-radius: 10px;*/
}

.fullWidthImage {
  border-radius: 25px;
  width: 100%;
}

.imgContainer {
  padding: 20px;
  float: left;
}

@media screen and (max-width: 768px) {
  .imgContainer {
    float: none;
    
  }
}

.imgContainer img {
  border-radius: 10px;
  max-width: 350px;
  width: 100%;
}

.imgText {
  min-width: 200px;
  padding: 20px;
}